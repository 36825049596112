import blancherie from '../assets/categoriesLogo/blancherie.png'
import cuisine from '../assets/categoriesLogo/cuisine.png'
import netoy from '../assets/categoriesLogo/netoy.png'
import education from '../assets/categoriesLogo/education.png'
import personneAge from '../assets/categoriesLogo/personneAge.png'
import securite from '../assets/categoriesLogo/securite.png'
import decoation from '../assets/categoriesLogo/decoation.png'
import maintenance from '../assets/categoriesLogo/maintenance.png'
import jardinage from '../assets/categoriesLogo/jardinage.png'
import soinsAnimaux from '../assets/categoriesLogo/soinsAnimaux.png'
import massage from '../assets/categoriesLogo/massage.png'
import livraison from '../assets/categoriesLogo/livraison.png'


export const categories = [
  {
    icon: blancherie,
    name: 'Blanchisserie',
    services: [
      'Lavage, séchage et pliage du linge de maison',
      'Lavage, séchage et repassage des vêtements',
      'Nettoyage à sec pour les articles délicats',
      'Traitement des taches tenaces sur les vêtements',
      'Nettoyage et réparation des chaussures',
      'Repassage de chemises et de vêtements formels',
      'Nettoyage et désinfection des couettes et oreillers',
      'Lavage et repassage des nappes et des rideaux',
      'Service de ramassage et de livraison du linge',
      'Nettoyage de vestes en cuir et en daim',
      'Lavage et repassage des uniformes',
      'Nettoyage des tapis et des tapisseries',
      'Nettoyage des articles en cuir et en daim',
      'Nettoyage et désodorisation des chaussures de sport',
      'Nettoyage des accessoires en cuir (sacs, ceintures, etc.)',
      'Nettoyage des articles en soie et en satin',
      'Nettoyage des articles de sport (casques, gants, etc.)',
      'Nettoyage et pressage des vêtements de cérémonie',
      'Nettoyage des articles en fourrure',
      'Nettoyage et traitement des tissus délicats',
    ]
  },
  {
    icon: cuisine,
    name: ' Cuisine et  restauration',
    services: [
      'Préparation de repas quotidiens pour le déjeuner et le dîner',
      'Service traiteur pour des événements spéciaux',
      'Cours de cuisine à domicile',
      'Organisation de dîners à thème',
      'Buffets et cocktails pour les fêtes et réceptions',
      'Préparation de plateaux-repas pour les déjeuners',
      'Service de chef privé pour les occasions spéciales',
      'Élaboration de menus équilibrés',
      'Préparation de plats végétariens et végétaliens',
      'Cuisson de plats régionaux et internationaux',
      'Préparation de repas adaptés à des régimes spécifiques (sans gluten, sans lactose, etc.)',
      'Organisation de dégustations culinaires',
      'Cuisson de plats pour les grandes occasions (Noël, anniversaires, etc.)',
      'Service de pâtisserie et de desserts personnalisés',
      'Cours de cuisine pour enfants',
      'Organisation de brunchs et de petits-déjeuners spéciaux',
      'Préparation de cocktails et de boissons rafraîchissantes',
      'Service de livraison de repas à domicile',
      'Conseils en nutrition et en diététique',
      'Préparation de repas pour les personnes âgées ou ayant des besoins spécifiques',
    ]
  },
  {
    icon: netoy,
    name: 'Nettoyage et entretien',
    services: [
      'Nettoyage régulier de la maison',
      'Nettoyage des vitres et des miroirs',
      'Nettoyage en profondeur des cuisines et des salles de bains',
      'Entretien des sols (balayage, aspirateur, lavage)',
      'Dépoussiérage des surfaces et des meubles',
      'Nettoyage des appareils électroménagers',
      'Désinfection des surfaces et des poignées',
      'Élimination des moisissures et des taches tenaces',
      'Nettoyage des tapis et des tapisseries',
      'Nettoyage des rideaux et des stores',
      'Lavage des volets et des persiennes',
      'Nettoyage des meubles rembourrés (canapés, chaises, etc.)',
      'Nettoyage des plafonds et des murs',
      'Nettoyage des luminaires et des abat-jour',
      'Nettoyage des cheminées et des inserts',
      'Nettoyage des systèmes de ventilation et des conduits d\'aération',
      'Nettoyage des ventilateurs de plafond',
      'Nettoyage des objets de décoration',
      'Désinfection des appareils électroniques (téléviseurs, télécommandes, etc.)',
      'Nettoyage des surfaces en bois (parquets, meubles, etc.)',
    ]
  },
  {
    icon: education,
    name: 'Garde d\'enfants et étude de maison',
    services: [
      'Garde d\'enfants à domicile',
      'Aide aux devoirs et soutien scolaire',
      'Organisation d\'activités ludiques et éducatives',
      'Préparation des repas pour les enfants',
      'Promenade et sortie au parc',
      'Babysitting pour les soirées et les événements',
      'Garde d\'enfants pendant les vacances scolaires',
      'Gestion du calendrier des activités des enfants',
      'Activités créatives et artistiques (peinture, dessin, etc.)',
      'Jouer à des jeux éducatifs',
      'Lecture d\'histoires et de contes',
      'Organisation de fêtes d\'anniversaire pour enfants',
      'Initiation à la musique (instruments, chant, etc.)',
      'Cours de langues étrangères pour enfants',
      'Sorties culturelles et éducatives',
      'Initiation à la cuisine pour enfants',
      'Ateliers de bricolage et de loisirs créatifs',
      'Activités sportives et jeux en plein air',
      'Cours de danse et de théâtre pour enfants',
      'Initiation à l\'informatique et aux nouvelles technologies',
    ]
  },
  {
    icon: personneAge,
    name: 'Soutien aux personnes âgées',
    services: [
      'Aide à la toilette et à l\'hygiène personnelle',
      'Aide à l\'habillage et au déshabillage',
      'Aide à la prise des repas',
      'Administration des médicaments',
      'Assistance pour les déplacements et la mobilité',
      'Gestion de l\'emploi du temps et des rendez-vous médicaux',
      'Aide aux travaux ménagers légers',
      'Accompagnement lors des sorties et des promenades',
      'Activités de loisirs et de divertissement',
      'Écoute et compagnie pour rompre l\'isolement',
      'Aide aux activités cognitives (jeux, puzzles, etc.)',
      'Aide pour l\'utilisation des appareils électroniques',
      'Organisation d\'activités adaptées à leurs intérêts',
      'Aide à la communication avec la famille et les amis',
      'Aide pour la gestion des papiers administratifs',
      'Assistance pour l\'utilisation des aides techniques (prothèses, cannes, etc.)',
      'Soutien psychologique et moral',
      'Accompagnement lors des visites médicales',
      'Aide pour l\'entretien du domicile',
      'Coordination des services médicaux et paramédicaux',
    ]
  },
  {
    icon: securite,
    name: 'Sécurité et  surveillance',
    services: [
      'Installation de systèmes d\'alarme et de vidéosurveillance',
      'Surveillance du domicile pendant les vacances',
      'Contrôle d\'accès et gestion des clés',
      'Surveillance des caméras de sécurité en temps réel',
      'Installation de détecteurs de fumée et de gaz',
      'Installation de systèmes de sécurité pour piscines',
      'Surveillance des ouvertures (fenêtres, portes, etc.)',
      'Service de gardiennage pour les résidences secondaires',
      'Installation de systèmes d\'interphone et de vidéophone',
      'Conseils en sécurité et prévention des intrusions',
      'Surveillance du périmètre extérieur',
      'Service d\'alerte en cas d\'urgence médicale',
      'Mise en place de systèmes de contrôle domotique',
      'Service de surveillance 24/7 avec intervention d\'urgence',
      'Surveillance des lieux par des agents de sécurité',
      'Installation de serrures et de verrous de haute sécurité',
      'Surveillance des lieux pendant les travaux de rénovation',
      'Conseils en cybersécurité pour la protection des données',
      'Surveillance des accès aux espaces sensibles (bureaux, entrepôts, etc.)',
      'Service de sécurité pour les événements spéciaux',
    ]
  },
  {
    icon: decoation,
    name: 'Décoration et aménagement d\'intérieur',
    services: [
      'Conception de plans d\'aménagement intérieur',
      'Choix des couleurs, des revêtements et des matériaux',
      'Sélection du mobilier et des accessoires décoratifs',
      'Mise en place de solutions de rangement optimisées',
      'Création d\'espaces de vie fonctionnels et esthétiques',
      'Aménagement des espaces extérieurs (patios, terrasses, etc.)',
      'Création d\'ambiances spécifiques (zen, moderne, vintage, etc.)',
      'Conseils en éclairage et mise en place de solutions d\'éclairage adaptées',
      'Décoration et aménagement de chambres d\'enfants',
      'Réaménagement et mise en valeur des espaces existants',
      'Décoration de chambres d\'hôtes et d\'hôtels',
      'Aménagement d\'espaces de travail ergonomiques',
      'Conception de cuisines fonctionnelles et esthétiques',
      'Décoration de salles de bains et de spas',
      'Aménagement d\'espaces pour personnes à mobilité réduite',
      'Création d\'espaces de détente et de relaxation',
      'Aménagement de bibliothèques et de bureaux à domicile',
      'Conception de salles de jeux et d\'espaces de loisirs',
      'Décoration de salles de réception et de fêtes',
      'Création de décors thématiques pour événements spéciaux',
    ]
  },
  {
    icon: maintenance,
    name: 'Réparation et  maintenance',
    services: [
      'Réparation et entretien des appareils électroménagers',
      'Réparation des systèmes de plomberie (robinetterie, fuites, etc.)',
      'Entretien et réparation des systèmes de chauffage et de climatisation',
      'Réparation des systèmes électriques et des luminaires',
      'Entretien des systèmes de ventilation et de climatisation',
      'Réparation des portes et des fenêtres (serrures, charnières, etc.)',
      'Entretien des toitures et des gouttières',
      'Réparation et remplacement des carreaux et des revêtements de sol',
      'Entretien et réparation des meubles (resserrage, remplacement de pièces, etc.)',
      'Réparation et remplacement des stores et des rideaux',
      'Entretien des jardins et des espaces extérieurs',
      'Réparation des clôtures et des portails',
      'Entretien et réparation des systèmes de sécurité (alarmes, caméras, etc.)',
      'Réparation des murs et des plafonds (rebouchage, peinture, etc.)',
      'Entretien et réparation des piscines et des équipements de loisirs',
      'Réparation des équipements de sport et de fitness',
      'Entretien des équipements informatiques et électroniques',
      'Réparation et restauration de meubles anciens',
      'Entretien et réparation des équipements de jardinage',
      'Réparation des véhicules et des bicyclettes',
    ]
  },
  {
    icon: jardinage,
    name: 'Jardinage ',
    services: [
      'Tonte de la pelouse et entretien du gazon',
      'Taille des arbres, arbustes et haies',
      'Plantation de fleurs, d\'arbustes et d\'arbres',
      'Entretien des parterres de fleurs et des massifs',
      'Traitement des maladies et parasites du jardin',
      'Conception et création de jardins',
      'Installation de systèmes d\'arrosage automatique',
      'Aménagement de potagers et de jardins potagers',
      'Création d\'espaces de détente dans le jardin (terrasses, pergolas, etc.)',
      'Entretien des allées et des chemins de jardin',
      'Taille et palissage des plantes grimpantes',
      'Aménagement de jardins à thème (jardin zen, jardin anglais, etc.)',
      'Élagage des arbres et des arbustes',
      'Création de rocailles et de jardins de pierres',
      'Entretien des bassins et des fontaines',
      'Plantation et entretien des plantes d\'intérieur',
      'Création de jardins de faible entretien',
      'Désherbage et nettoyage du jardin',
      'Entretien des espaces verts dans les entreprises et les copropriétés',
      'Aménagement de jardins pour la biodiversité et l\'écologie',
    ]
  },
  {
    icon: soinsAnimaux,
    name: 'Soins aux animaux domestiques',
    services: [
      'Promenade quotidienne pour les chiens',
      'Garde d\'animaux à domicile pendant les vacances',
      'Soins et toilettage des animaux de compagnie',
      'Alimentation et distribution des repas pour les animaux',
      'Nettoyage et entretien des litières pour les chats',
      'Jeu et stimulation mentale pour les animaux',
      'Visites régulières chez le vétérinaire',
      'Soin des animaux malades ou convalescents',
      'Entraînement et éducation des chiots',
      'Garde d\'animaux en famille d\'accueil',
      'Soin des animaux âgés ou ayant des besoins spécifiques',
      'Garde de rongeurs et d\'oiseaux de compagnie',
      'Garde d\'animaux exotiques et reptiles',
      'Garde d\'animaux de ferme et d\'élevage',
      'Garde d\'animaux pendant les événements spéciaux',
      'Transport des animaux chez le vétérinaire',
      'Service de toilettage à domicile',
      'Conseils en alimentation et en soins pour les animaux',
      'Garde d\'animaux pendant les déplacements professionnels',
      'Garde d\'animaux en cas d\'hospitalisation du propriétaire',
    ]
  },
  {
    icon: massage,
    name: 'Bien-être et  soins personnels',
    services: [
      'Massages relaxants et thérapeutiques',
      'Soins du visage et des soins de la peau',
      'Manucure et pédicure à domicile',
      'Coiffure et coupe de cheveux à domicile',
      'Maquillage pour des événements spéciaux',
      'Soins des cheveux (traitement, coloration, etc.)',
      'Modelage du corps et enveloppements',
      'Épilation à domicile',
      'Réflexologie et acupuncture',
      'Yoga et méditation à domicile',
      'Cours de fitness et de remise en forme',
      'Consultation en nutrition et en bien-être',
      'Conseils en mode et en image personnelle',
      'Coaching en développement personnel',
      'Cours de relaxation et de gestion du stress',
      'Séances de reiki et de thérapies énergétiques',
      'Soins du dos et de la colonne vertébrale',
      'Cours de danse et de danse-thérapie',
      'Ateliers de relaxation et de pleine conscience',
      'Soins de spa et de bien-être à domicile',
    ]
  },
  {
    icon: livraison,
    name: 'Livraison et aide aux courses',
    services: [
      'Livraison de courses et de produits alimentaires',
      'Service de livraison de médicaments à domicile',
      'Livraison de repas préparés et de plats à emporter',
      'Courses et achats pour le compte du ménage',
      'Livraison de produits d\'hygiène et de nettoyage',
      'Aide aux courses pour les personnes âgées ou à mobilité réduite',
      'Livraison de fleurs et de cadeaux',
      'Courses pour animaux de compagnie (nourriture, litière, etc.)',
      'Livraison de matériel et d\'équipements divers',
      'Courses et achats pour les événements spéciaux',
      'Livraison de journaux et de magazines',
      'Courses pour les urgences et les besoins imprévus',
      'Livraison de produits de jardinage et d\'aménagement extérieur',
      'Aide aux courses pour les familles occupées',
      'Livraison de fournitures scolaires et de matériel éducatif',
      'Courses pour les personnes convalescentes',
      'Livraison de matériel informatique et électronique',
      'Aide aux courses pour les personnes en convalescence',
      'Livraison de matériel de bricolage et de réparation',
      'Courses pour les professionnels et les entreprises',
    ]
  },
];


