export const images = {
    im1: `https://img.freepik.com/free-photo/side-view-man-receiving-groceries_23-2149722284.jpg?size=626&ext=jpg&uid=R70544533&ga=GA1.2.530977551.1681331791&semt=ais`,
    im2: `https://img.freepik.com/free-photo/positive-dark-skinned-ethnic-woman-with-dreadlocks-holds-detergent-mop_273609-50223.jpg?size=626&ext=jpg&uid=R70544533&ga=GA1.1.530977551.1681331791&semt=ais`,
    im3: `https://img.freepik.com/free-photo/full-shot-mother-playing-with-kid_23-2149748350.jpg?size=626&ext=jpg&uid=R70544533&ga=GA1.2.530977551.1681331791&semt=ais`,
    im4: `
   https://img.freepik.com/photos-gratuite/femme-enseignant-aux-enfants-classe_23-2148892552.jpg?w=740&t=st=1690819882~exp=1690820482~hmac=d8c0734c5d74dfd59ad48997d42f9167e70ff7be4e8887ae87ef0fd3e3b00d83
    `,
    im5: `
  https://img.freepik.com/photos-premium/plan-jeune-homme-se-faisant-masser-dos-dans-spa_590464-45263.jpg?w=740
    `,
    im6: `
https://img.freepik.com/free-photo/confident-head-cook-standing-restaurant-professional-kitchen-with-arms-crossed-while-smiling-camera-sous-chef-wearing-cooking-uniform-while-preparing-ingredients-dinner-service_482257-44194.jpg?size=626&ext=jpg&uid=R70544533&ga=GA1.2.530977551.1681331791&semt=ais
    `,
    im7: `
    https://img.freepik.com/free-photo/professional-washer-blue-uniform-washing-luxury-car-with-water-gun-open-air-car-wash_496169-333.jpg?size=626&ext=jpg&uid=R70544533&ga=GA1.1.530977551.1681331791&semt=ais
    `,
    im8: `
   https://img.freepik.com/photos-premium/ingenieur-travaillant-reparer-panneau-electrique-fond-panneaux-solaires-travail-equipe-concept-formation-renouvelable_28914-1715.jpg?w=740
    `
}